<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="./images/组 632.png" style="width: 100%;">
            <div slot="title" style="margin-bottom: 100px;">
                <div>食品检测科技,服务健康生活</div>
                <div style="font-size: 30px">Food testing technology, serving healthy life</div>
            </div>
        </HeadContent>

        <!--    关于国科 s-->
        <div class="home-about">
            <div class="home-about-c">
                <div class="home-about-l">
                    <div class="mtitle">关于国科</div>
                    <div>重庆国科食品检测有限公司是一家专业的第三方检验认证机构集科研、培训、服务于一体的高科技服务型企业。<br>
                        提供检验检测、认证、安全评价、计量服务、标准制定、咨询培训等服务。为企业提供全链条、全方位、全过程、一站式综合服务。
                    </div>
                    <div @click="goRouter('Gsituation')">
                        <span>了解更多</span>
                        <i class="el-icon-right"></i>
                    </div>
                </div>
                <div class="home-about-r">
                    <img src="./images/蒙版组 273.png" alt="">
                </div>
            </div>
        </div>
        <!--    关于国科 e-->

        <!--    我们的愿景 s-->
        <div class="wmdyj">
            <div class="wmdyj-c">
                <div>我们的愿景</div>
                <div>以服务中小微企业为核心<br>
                    做政府的助手，做消费者的朋友
                </div>
                <div></div>
            </div>
        </div>
        <!--    我们的愿景 e-->


        <!--    我们的服务 s-->
        <div class="home-fw-box">
            <div class="home-fw">
                <div class="home-fw-l">
                    我们的服务
                </div>
                <div class="home-fw-r">
                    <div class="home-fw-r-box">
                        <div class="movebox">
                            <div class="home-fw-r-col">
                                <div class="imgbox">
                                    <img src="./images/组 636.png" alt="">
                                </div>
                                <span>检验检测</span>
                            </div>

                            <div class="home-fw-r-col">
                                <div class="imgbox">
                                    <img src="./images/蒙版组 275.png" alt="">
                                </div>
                                <span>认证认可</span>
                            </div>

                            <div class="home-fw-r-col">
                                <div class="imgbox">
                                    <img src="./images/组 638.png" alt="">
                                </div>
                                <span>咨询服务</span>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!--    最新动态s-->
        <div class="zxdt">
            <div class="gtit">最新动态</div>
            <div class="zxdt-c">
                <div class="zxdt-col" v-for="(item,index) in newsList" :key="index">
                    <div class="zxdt-col-l">
                        <div @click="goMore(item.articleId,'企业新闻')">{{ item.title }}</div>
                        <div>{{ item.summary }}</div>
                        <div>{{ item.createDate }}</div>
                        <div @click="goRouter('informationCenter',{type:'home'})">
                            了解更多
                        </div>
                    </div>
                    <img :src="item.picture" alt="">
                </div>
            </div>

        </div>
        <!--    最新动态e-->

        <!--    合作伙伴 s-->
        <div class="hz-c">
            <div style="width: 1200px;margin: 0 auto;">
                <div class="mtitle">合作伙伴</div>
            </div>
            <div class="tech-slideshow">
                <div class="mover-1">
                    <div class="hzlog-b" v-for="(item,index) in row1Img">
                        <img :src="item.src" alt="">
                    </div>
                    <div class="hzlog-b" v-for="(item,index) in row1Img">
                        <img :src="item.src" alt="">
                    </div>
                </div>
            </div>


            <div class="tech-slideshow2">
                <div class="mover-2">
                    <div class="hzlog-b" v-for="(item,index) in row2Img">
                        <img :src="item.src" alt="">
                    </div>
                    <div class="hzlog-b" v-for="(item,index) in row2Img">
                        <img :src="item.src" alt="">
                    </div>
                </div>
            </div>

            <div class="tech-slideshow">
                <div class="mover-1">
                    <div class="hzlog-b" v-for="(item,index) in row3Img">
                        <img :src="item.src" alt="">
                    </div>
                    <div class="hzlog-b" v-for="(item,index) in row3Img">
                        <img :src="item.src" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!--    合作伙伴 e-->

        <!--    选择我们s-->
        <div class="hxzwm">
            <div class="hxzwm-t">选择我们</div>
            <div class="hxzwm-c">
                <div class="hxzwm-col">
                    <img src="./images/xzwmicon (3).png" alt="">
                    <div>专业公正</div>
                    <div>
                        具备cma等多项国内外资质的<br>
                        第三方检测机构
                    </div>
                </div>
                <div class="hxzwm-col">
                    <img src="./images/xzwmicon (1).png" alt="">
                    <div>严谨高效</div>
                    <div>
                        高规格实验室标准<br>
                        工程师一对一服务
                    </div>
                </div>
                <div class="hxzwm-col">
                    <img src="./images/xzwmicon (2).png" alt="">
                    <div>领域广泛</div>
                    <div>
                        从原料到上市<br>
                        食品农产品业覆盖领域广
                    </div>
                </div>
                <div class="hxzwm-col">
                    <img src="./images/xzwmicon (4).png" alt="">
                    <div>创新服务</div>
                    <div>
                        一站式服务<br>
                        整体解决方案
                    </div>
                </div>
            </div>
        </div>
        <!--    选择我们e-->
    </div>
</template>

<script>
    import newsApi from "@/api/news.js"

    export default {
        data() {
            return {
                accenindex: '0',
                // hzLogo:[require("../../assets/images/蒙版组 62.png"),]
                xzwmimg: [{
                    img: require("../../assets/images/查验.png"),
                    tit: "专业公正",
                    intr: "具备cma等多项国内外资质的第三方检测机构"
                }, {
                    img: require("../../assets/images/溯源 (1).png"),
                    tit: "严谨高效",
                    intr: "高规格实验室标准\n" +
                        "工程师一对一服务"
                }, {
                    img: require("../../assets/images/灵活扩展.png"),
                    tit: "领域广泛",
                    intr: "从原料到上市\n" +
                        "食品农产品行业覆盖领域广"
                }, {
                    img: require("../../assets/images/真实可信.png"),
                    tit: "创新服务",
                    intr: "一站式服务\n" +
                        "整体解决方案"
                }, {
                    img: require("../../assets/images/联盟链.png"),
                    tit: "创新服务",
                    intr: "一站式服务\n" +
                        "整体解决方案"
                }],
                introActive: 0,
                fwActive: 0,
                newsList: [],
                row1Img: [
                    {src: require('./images/row1/组 659.png')},
                    {src: require('./images/row1/组 660.png')},
                    {src: require('./images/row1/组 661.png')},
                    {src: require('./images/row1/组 662.png')},
                    {src: require('./images/row1/组 663.png')},
                    {src: require('./images/row1/组 673.png')},
                    {src: require('./images/row1/组 683.png')}
                ],
                row2Img: [
                    {src: require('./images/row2/组 665.png')},
                    {src: require('./images/row2/组 666.png')},
                    {src: require('./images/row2/组 671.png')},
                    {src: require('./images/row2/组 674.png')},
                    {src: require('./images/row2/组 675.png')},
                    {src: require('./images/row2/组 676.png')}
                ],
                row3Img: [
                    {src: require('./images/row3/组 672.png')},
                    {src: require('./images/row3/组 677.png')},
                    {src: require('./images/row3/组 678.png')},
                    {src: require('./images/row3/组 679.png')},
                    {src: require('./images/row3/组 680.png')},
                    {src: require('./images/row3/组 681.png')},
                    {src: require('./images/row3/组 682.png')}
                ],
                resData: {
                    "total": 3,
                    "list": [{
                        "searchValue": null,
                        "createBy": null,
                        "createTime": null,
                        "updateBy": null,
                        "updateTime": null,
                        "remark": null,
                        "params": {},
                        "articleId": 1,
                        "author": "",
                        "title": "匈牙利驻重庆领事馆总领事一行莅临重庆国科考察",
                        "summary": "为积极响应中国“一带一路”战略决策，加强与欧盟国家匈牙利开展食品产业技术交流，2021 年1月5日，匈牙利驻重庆总领事馆葛凯达总领事（Kádár Gergely)等一行人，在重庆西部食品产业技术研究院刘雄院长的陪同下，考察参观了重庆国科食品检测有限公司实验室。",
                        "picture": "https://www.gukotest.com/system/profile/upload/2021/08/30/6595a585-b0e2-4ca4-a21d-1fd2d722d806.png",
                        "imgUrl": null,
                        "htmlContent": null,
                        "createDate": "2021-07-29",
                        "enable": 1
                    }, {
                        "searchValue": null,
                        "createBy": null,
                        "createTime": null,
                        "updateBy": null,
                        "updateTime": null,
                        "remark": null,
                        "params": {},
                        "articleId": 4,
                        "author": "",
                        "title": "重庆国科食品检测有限公司扩项评审",
                        "summary": "2021年1月15日，资质认定评审组对重庆国科食品检测有限公司进行扩项评审。",
                        "picture": "https://gukotest.com/system/profile/upload/2021/05/24/acef3dbc-3932-4957-b1d3-19b5128388f4.png",
                        "imgUrl": null,
                        "htmlContent": null,
                        "createDate": "2021-05-24",
                        "enable": 1
                    }, {
                        "searchValue": null,
                        "createBy": null,
                        "createTime": null,
                        "updateBy": null,
                        "updateTime": null,
                        "remark": null,
                        "params": {},
                        "articleId": 2,
                        "author": "",
                        "title": "重庆国科食品检测有限公司 参加重庆市促进食品产业高质量发展服务对接会",
                        "summary": "重庆市经济和信息化委员会消费品副处长王庆军、江津区经济和信息化委员会副主任谢治刚、重庆市食品工业协会徽章罗其胜参加了会议",
                        "picture": "https://gukotest.com/system/profile/upload/2021/05/24/acef3dbc-3932-4957-b1d3-19b5128388f4.png",
                        "imgUrl": null,
                        "htmlContent": null,
                        "createDate": "2021-05-19",
                        "enable": 1
                    }],
                    "pageNum": 1,
                    "pageSize": 3,
                    "size": 3,
                    "startRow": 1,
                    "endRow": 3,
                    "pages": 1,
                    "prePage": 0,
                    "nextPage": 0,
                    "isFirstPage": true,
                    "isLastPage": true,
                    "hasPreviousPage": false,
                    "hasNextPage": false,
                    "navigatePages": 8,
                    "navigatepageNums": [1],
                    "navigateFirstPage": 1,
                    "navigateLastPage": 1
                }
            }
        },
        created() {
            this.newsList = this.resData.list
            this.getNews()
        },
        methods: {
            getAccen(index) {
                this.accenindex = index.index
            },
            introItemClick(index) {
                this.introActive = index
            },
            getNews() {
                newsApi.homeNews().then(res => {
                    this.newsList = res.list
                })
            },
            fwItemClick(index) {
                this.fwActive = index
            },
            goMore(id, title) {
                console.log(id)
                this.$router.push({
                    name: "article",
                    query: {id, title, type: 'home'}
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .gtit {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    //关于国科 s
    .home-about {
        padding: 100px 0 90px 0;
        @include page1200;

        .home-about-c {
            margin-top: 40px;
            display: flex;

            .home-about-l {
                margin-right: 90px;
                padding-top: 10px;

                div:nth-child(2) {
                    margin-top: 60px;
                    width: 510px;
                    height: 240px;
                    font-size: 22px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 42px;
                    color: #333333;
                    color: #333333;
                    margin-bottom: 110px;
                }

                div:nth-child(3) {
                    padding-bottom: 16px;
                    position: relative;
                    width: 100%;
                    display: flex;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #606060;
                    opacity: 0.76;
                    justify-content: space-between;
                    align-items: center;

                    i {
                        transform: scaleX(1.4);
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 0px;
                        border: 1px solid #707070;
                        bottom: 0;
                        left: 0;
                        opacity: 0.26;
                    }

                    &:hover {
                        cursor: pointer;
                        color: #1320E8;
                    }
                }

            }

            .home-about-r {
                width: 560px;
                height: 531px;
                position: relative;

                img {
                    width: 100%;
                    height: 531px;
                }

                &:after {
                    position: absolute;
                    content: "";
                    width: 52px;
                    height: 438px;
                    background: #1320E8;
                    right: 0;
                    bottom: 0;
                    transform: translateX(40px);
                    z-index: -1;
                }
            }

        }
    }

    //关于国科 e

    //我们的愿景 s
    .wmdyj {
        width: 100%;
        height: 406px;
        background: #FAFAFA;
        padding-top: 80px;

        .wmdyj-c {
            @include page1200;
            display: flex;
            flex-direction: column;
            align-items: center;
            div:nth-child(1) {
                font-size: 36px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 90px;
                color: #333333;
            }

            div:nth-child(2) {
                @include flexCenter;
                text-align: center;
                margin-top: 33px;
                margin-bottom: 52px;
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 46px;
                color: #333333;
            }

            div:nth-child(3) {
                width: 30px;
                height: 3px;
                background: #1320E8;
            }
        }
    }

    //我们的愿景 e

    //我们的服务 s
    .home-fw-box {
        color: #fff;
        padding-top: 90px;
        padding-bottom: 90px;
        width: 100%;
        background: #0E2565;
        margin-top: 50px;
    }

    .home-fw {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include page1200;

        .home-fw-l {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 90px;
            color: #FFFFFF;
            margin-bottom: 63px;
        }

        .home-fw-r {
            position: relative;

            .home-fw-r-box {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            .movebox {
                display: flex;
            }

            .home-fw-r-col {
                margin-right: 26px;
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 500;
                position: relative;

                .imgbox {
                    width: 380px;
                    height: 410px;
                    background: rgba(#E8E8E8, 0.22);
                    @include flexCenter;
                    flex-direction: column;
                    margin-bottom: 30px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: #333333;
                        opacity: 0.18;
                    }

                }

                img {
                    width: 100%;
                    height: 480px;
                }

                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 120px;
                    font-size: 28px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    background: #FFFFFF;
                    padding-top: 50px;
                    padding-left: 30px;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 35px;
                        left: 30px;
                        width: 30px;
                        height: 3px;
                        background: #CCCCCC;
                    }
                }

                &:hover {
                    cursor: pointer;
                    span {
                        &:after {
                            background: #1320E8;
                        }
                    }
                }

            }
        }
    }

    //我们的服务 e

    //合作伙伴 s
    .hz-c {
        padding-top: 90px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .hzlog-b {
            width: 285px;
            height: 150px;
            opacity: .36;
        }

        .hzlog-b:hover {
            cursor: pointer;
            opacity: 1;
        }

    }

    .tech-slideshow, .tech-slideshow2 {
        height: 200px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
    }

    .tech-slideshow > div {
        display: flex;
        align-items: center;
        //background: url(http://www.webhek.com/wordpress/wp-content/uploads/2018/03/collage.jpg);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    .tech-slideshow .mover-1 {
        animation: moveSlideshow 60s linear infinite;
    }

    .tech-slideshow2 > div {
        display: flex;
        align-items: center;
        //background: url(http://www.webhek.com/wordpress/wp-content/uploads/2018/03/collage.jpg);
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    .tech-slideshow2 .mover-2 {
        animation: moveSlideshow2 60s linear infinite;
    }

    @keyframes moveSlideshow {
        100% {
            transform: translateX(-50%);
        }
    }

    @keyframes moveSlideshow2 {
        100% {
            transform: translateX(50%);
        }
    }

    /*合作伙伴 e*/

    .zxdt {
        width: 100%;
        background: #FAFAFA;
        padding-top: 90px;
        padding-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .zxdt-c {
            @include page1200;
            padding-top: 40px;
            width: 1080px;
            margin: 0 auto;

            .zxdt-col {
                width: 100%;
                margin-bottom: 50px;
                background: #fff;
                height: 260px;
                justify-content: space-between;
                display: flex;

                .zxdt-col-l {
                    padding: 45px 46px 35px 35px;
                    div:nth-child(1) {
                        width: 654px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 22px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        &:hover {
                            color: #002fdf;
                            cursor: pointer;
                        }
                    }

                    div:nth-child(2) {
                        margin-top: 20px;
                        margin-bottom: 15px;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /*第几行显示省略号*/
                        -webkit-box-orient: vertical;
                        height: 46px;
                        overflow: hidden;
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 26px;
                        color: #666666;
                    }

                    div:nth-child(3) {
                        font-size: 14px;
                        margin-bottom: 30px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #999999;
                    }

                    div:nth-child(4) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #1320E8;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                }

                img {
                    width: 345px;
                    height: 260px;
                    position: relative;
                }
            }
        }
    }

    //选择我们s
    .hxzwm {
        width: 100%;
        height: 580px;
        background: #FAFAFA;
        @include flexCenter;
        flex-direction: column;

        .hxzwm-t {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .hxzwm-c {
            @include page1200;
            padding-top: 40px;
            display: flex;
            .hxzwm-col {
                width: 201px;
                height: 257px;
                margin-right: 122px;
                flex-shrink: 0;
                @include flexCenter;
                flex-direction: column;
                transition: all linear .3s;
                img {
                    transition: all linear .3s;
                }

                img {
                    width: 80px;
                    height: 80px;
                }
                div:nth-child(2) {
                    margin-top: 28px;
                    margin-bottom: 10px;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #606060;
                }

                div:nth-child(3) {
                    font-size: 10px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #888888;
                    text-align: center;
                }

                &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
                    img {
                        width: 98px;
                        height: 98px;
                    }
                    div:nth-child(2) {
                        font-size: 24px;
                        font-weight: 600;
                        color: #333333;
                    }

                    div:nth-child(3) {
                        font-size: 15px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #888888;
                    }
                }
            }
        }
    }

    //选择我们e
</style>
